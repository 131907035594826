// eslint-disable-next-line import/prefer-default-export
export const TEAMS = {
    AUSTRALIA: 'australia',
    SOUTHAFRICA: 'southAfrica',
    ANALYTICS: 'analytics',
    COMMERCIAL: 'commercial',
    CUSTOPS: 'custops',
    DESIGN: 'design',
    FINANCELEGAL: 'financeLegal',
    MARKETING: 'marketing',
    OFFICE: 'office',
    PPP: 'ppp',
    PRODUCT: 'product',
    TECH: 'tech',
};

export const PAGE_NAMESPACE = 'careers-teams';
