import { GB } from '@clearscore-group/lib.config.i18n';

import { TEAMS } from './constants';

export default {
    [GB]: {
        jobListings: {
            viewAllJobs: '/careers/jobs?expand=1',
            maxJobsDisplayed: 5,
        },
        teams: [
            {
                key: TEAMS.ANALYTICS,
                stories: [
                    {
                        key: 'sophie',
                    },
                    {
                        key: 'luke',
                    },
                ],
            },
            {
                key: TEAMS.COMMERCIAL,
                stories: [
                    {
                        key: 'kasperi',
                    },
                    {
                        key: 'sarah',
                    },
                ],
            },
            {
                key: TEAMS.CUSTOPS,
                stories: [
                    {
                        key: 'katrina',
                    },
                    {
                        key: 'charlotte',
                    },
                ],
            },
            {
                key: TEAMS.DESIGN,

                stories: [
                    {
                        key: 'laura',
                    },
                    {
                        key: 'sebastian',
                    },
                ],
            },
            {
                key: TEAMS.FINANCELEGAL,
                stories: [
                    {
                        key: 'lloyd_k',
                    },
                    {
                        key: 'nikki',
                    },
                ],
            },
            {
                key: TEAMS.MARKETING,
                stories: [
                    {
                        key: 'donatella',
                    },
                    {
                        key: 'rob',
                    },
                ],
            },
            {
                key: TEAMS.OFFICE,
                stories: [
                    {
                        key: 'becky',
                    },
                ],
            },
            {
                key: TEAMS.PPP,
                stories: [
                    {
                        key: 'victor',
                    },
                    {
                        key: 'sofie',
                    },
                ],
            },
            {
                key: TEAMS.PRODUCT,
                stories: [
                    {
                        key: 'sneha',
                    },
                    {
                        key: 'thomas',
                    },
                ],
            },
            {
                key: TEAMS.TECH,
                stories: [
                    {
                        key: 'lydia',
                    },
                    {
                        key: 'david',
                    },
                ],
            },
            {
                key: TEAMS.AUSTRALIA,
                stories: [
                    {
                        key: 'ash',
                    },
                    {
                        key: 'lloyd_s',
                    },
                ],
            },
            {
                key: TEAMS.SOUTHAFRICA,
                stories: [
                    {
                        key: 'ayden',
                    },
                    {
                        key: 'roscoe',
                    },
                ],
            },
        ],
    },
};
