import React, { useState } from 'react';
import Template from '@clearscore/shared.website-template';
import {
    useTracking,
    CareersNav,
    PageHeader,
    SectionHeader,
    FullWidthImage,
    GLASSDOOR_RATING,
} from '@clearscore/shared.website-careers-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import Spacer from '@clearscore/ui.rainbow.spacer';

import TeamsNav from './components/teams-nav';
import { PAGE_NAMESPACE } from './lib/constants';
import TeamSection from './components/team-section';
import marketConfig from './lib/market-config';
import styles from './careers-teams.module.css';

const CareersTeams = ({ images, appStoreConfig, cookiePolicyConfig, categories }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const { jobListings: jobListingsConfig, teams: teamsConfig } = useMarketConfig(marketConfig);
    const { trackCareersContentClicked } = useTracking();
    const [activeTeamId, setActiveTeamId] = useState(teamsConfig[0].key);

    const selectedTeamConfig = teamsConfig.find(({ key }) => key === activeTeamId);
    const selectedTeamCopy = t(`teams.${activeTeamId}`);
    const selectedTeamStories = selectedTeamConfig.stories.map(({ key }) => ({
        image: images.stories[key],
        ...selectedTeamCopy.stories[key],
    }));
    const jobListingsCopy = {
        ...t('workable'),
        header: t('workable.header', { team: selectedTeamCopy.header }),
        glassdoorText: t('workable.glassdoorText', { rating: GLASSDOOR_RATING }),
    };

    return (
        <Template shouldHideNav appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
            <CareersNav items={categories} theme={CareersNav.Theme.LIGHT} />
            <article data-layout="full-bleed">
                <Spacer all={{ vertical: Spacer.spacings.SUPER }} large={{ vertical: Spacer.spacings.JUMBO }}>
                    <PageHeader preheader={t('pageHeader.preheader')} header={t('pageHeader.header')} />
                </Spacer>
                <section className={styles.heroImage}>
                    <FullWidthImage image={images.hero} alt={t('pageHeader.imageAlt')} />
                </section>
                <SectionHeader header={t('intro.header')} subheader={t('intro.subheader')} />
                <section className={styles.content}>
                    <Spacer
                        all={{ horizontal: Spacer.spacings.LARGE, vertical: Spacer.spacings.MEDIUM }}
                        large={{ left: Spacer.spacings.SUPER, vertical: Spacer.spacings.MICRO }}
                    >
                        <TeamsNav
                            teams={teamsConfig.map(({ key }) => key)}
                            activeTeamId={activeTeamId}
                            onClick={({ teamId }) => {
                                setActiveTeamId(teamId);
                                trackCareersContentClicked({
                                    type: 'team_page',
                                    description: t(`teams.${teamId}`)?.header,
                                });
                            }}
                        />
                    </Spacer>
                    <TeamSection
                        header={selectedTeamCopy.header}
                        body={selectedTeamCopy.body}
                        stories={selectedTeamStories}
                        jobListings={{ config: jobListingsConfig, language: jobListingsCopy }}
                    />
                </section>
            </article>
        </Template>
    );
};

CareersTeams.propTypes = {
    images: PropTypes.shape({
        hero: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
        stories: PropTypes.shape({
            sophie: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            luke: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            kasperi: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            sarah: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            katrina: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
            charlotte: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryNavPosition: PropTypes.number.isRequired,
            categoryUrl: PropTypes.string.isRequired,
            navTitle: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

CareersTeams.defaultProps = {};

export default CareersTeams;
