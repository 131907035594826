import React, { Fragment } from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import CareersTeams from '@clearscore/website.careers-teams';

import Layout from '../components/layout';
import { findImage, findStory, HiddenPageMeta } from './template-util';

const CareersTeamsTemplate = ({
    data,
    pageContext: { market, subNavRoutes, verticalVersion },
    location,
}: PageProps<
    Queries.CareersTeamsQuery,
    { market: string; verticalVersion: string; subNavRoutes: Array<{ navTitle: string; categoryUrl: string }> }
>): React.ReactElement => {
    const images = {
        hero: findImage(data, 'hero'),
        stories: {
            ...findStory(data, 'sophie'),
            ...findStory(data, 'luke'),
            ...findStory(data, 'kasperi'),
            ...findStory(data, 'sarah'),
            ...findStory(data, 'katrina'),
            ...findStory(data, 'charlotte'),
            ...findStory(data, 'laura'),
            ...findStory(data, 'sebastian'),
            ...findStory(data, 'nikki'),
            ...findStory(data, 'lloyd_k'),
            ...findStory(data, 'donatella'),
            ...findStory(data, 'rob'),
            ...findStory(data, 'emma'),
            ...findStory(data, 'becky'),
            ...findStory(data, 'victor'),
            ...findStory(data, 'sofie'),
            ...findStory(data, 'sneha'),
            ...findStory(data, 'thomas'),
            ...findStory(data, 'lydia'),
            ...findStory(data, 'david'),
            ...findStory(data, 'ash'),
            ...findStory(data, 'lloyd_s'),
            ...findStory(data, 'ayden'),
            ...findStory(data, 'roscoe'),
        },
    };

    return (
        <Layout
            meta={{
                title: ` Our Teams | ClearScore ${market.toUpperCase()} `,
                description: '',
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <Fragment>
                    <CareersTeams
                        images={images}
                        appStoreConfig={commonModules.app_store}
                        cookiePolicyConfig={commonModules.cookie_policy}
                        categories={subNavRoutes}
                    />
                    <HiddenPageMeta verticalVersion={verticalVersion} />
                </Fragment>
            )}
        </Layout>
    );
};

export const query = graphql`
    query CareersTeams {
        images: allFile(filter: { absolutePath: { regex: "/global/careers/teams/" } }) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(quality: 99, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                }
            }
        }
    }
`;

export default CareersTeamsTemplate;
