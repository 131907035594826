import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Text from '@clearscore/ui.rainbow.text';
import { useTranslation } from 'react-i18next';
import Spacer from '@clearscore/ui.rainbow.spacer';

import styles from './teams-nav.module.css';
import { PAGE_NAMESPACE } from '../../lib/constants';

const TeamsNav = ({ activeTeamId, teams, onClick }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    return (
        <nav className={styles.wrapper}>
            {teams.map((teamId) => (
                <Spacer
                    key={teamId}
                    all={{ right: Spacer.spacings.LARGE, bottom: Spacer.spacings.BIG }}
                    large={{ right: Spacer.spacings.MICRO, vertical: Spacer.spacings.SMALL }}
                >
                    <Text.Body3>
                        <button
                            type="button"
                            className={cx(styles.link, { [styles.isActiveLink]: teamId === activeTeamId })}
                            onClick={(e) => {
                                e.preventDefault();
                                onClick({ e, teamId });
                            }}
                        >
                            {t(`teams.${teamId}.header`)}
                        </button>
                    </Text.Body3>
                </Spacer>
            ))}
        </nav>
    );
};

TeamsNav.propTypes = {
    activeTeamId: PropTypes.string.isRequired,
    teams: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default TeamsNav;
