import React from 'react';
import PropTypes from 'prop-types';
import Text from '@clearscore/ui.rainbow.text';
import Spacer from '@clearscore/ui.rainbow.spacer';
import { StoriesSection, JobListings } from '@clearscore/shared.website-careers-components';

import styles from './team-section.module.css';

const TeamSection = ({ header, body, stories, jobListings }) => {
    const jobListingsProps = {
        language: jobListings.language,
        config: {
            ...jobListings.config,
            search: header,
        },
    };

    return (
        <div className={styles.wrapper}>
            <Spacer
                all={{
                    left: Spacer.spacings.LARGE,
                    right: Spacer.spacings.LARGE,
                    bottom: Spacer.spacings.SUPER,
                    top: Spacer.spacings.HUGE,
                }}
                large={{ right: Spacer.spacings.SUPER, top: Spacer.spacings.MICRO }}
                huge={{ right: Spacer.spacings.JUMBO }}
            >
                <Spacer all={{ bottom: Spacer.spacings.LARGE }}>
                    <h3 className={styles.header}>{header}</h3>
                </Spacer>
                <div className={styles.body}>
                    <Text.Body1>{body}</Text.Body1>
                </div>
                <StoriesSection shouldForceGrid items={stories} />
                <JobListings {...jobListingsProps} />
            </Spacer>
        </div>
    );
};

TeamSection.propTypes = {
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    stories: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            subheader: PropTypes.string.isRequired,
            body: PropTypes.string.isRequired,
            readMore: PropTypes.string.isRequired,
            readLess: PropTypes.string.isRequired,
            image: PropTypes.shape({
                small: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
                large: PropTypes.shape({ images: PropTypes.object.isRequired }).isRequired,
            }).isRequired,
        }).isRequired,
    ).isRequired,
    jobListings: PropTypes.shape({
        language: PropTypes.shape({
            header: PropTypes.string.isRequired,
            seeAll: PropTypes.string.isRequired,
            opportunities: PropTypes.string.isRequired,
            mobileCta: PropTypes.string.isRequired,
        }).isRequired,
        config: PropTypes.shape({
            viewAllJobs: PropTypes.string.isRequired,
            maxJobsDisplayed: PropTypes.number.isRequired,
        }).isRequired,
    }).isRequired,
};

export default TeamSection;
